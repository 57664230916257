import React from "react";
import ApplicationController from "../../view_components/application_controller.js";
import { createRoot } from "react-dom/client";
import FontPicker from "./components/FontPicker";
import { useAssetLoader } from "mixins";

//* https://www.webfontpreview.com/preview
export default class extends ApplicationController  {
  static values = {
    fonts: { type: String, default: "" },
  };

  connect() {
    this.log_function("connect");

    //* Gives access to this.load_style()
    useAssetLoader(this);

    //* Load all the fonts via the provided CSS file
    this.load_style(this.fontsValue);

    //* Create a root for React to latch on to
    const root = createRoot(this.element);

    //* Render the font picker
    root.render(<FontPicker />);
  }

  disconnect(){
    this.log_function("disconnect");
  }
}
