import ApplicationController from "../../view_components/application_controller.js";

//* <button
//*   data-controller="nest-view-9000"
//*   data-action="nest-view-9000#toggle"
//* >
//*   Toggle
//* </button>

export default class extends ApplicationController  {

  connect() {
    this.log_function("connect");

    this.is_active ? this.activate() : this.deactivate();
  }


  toggle(){
    this.log_function("toggle");

    this.is_active ? this.deactivate() : this.activate();
  }


  activate(){
    this.log_function("activate");

    document.body.classList.add("nest-view");
    this.element.classList.add("active");
    localStorage.setItem("nest_view_9000_active", true);
  }

  deactivate(){
    this.log_function("deactivate");

    document.body.classList.remove("nest-view");
    this.element.classList.remove("active");
    localStorage.removeItem("nest_view_9000_active");
  }

  disconnect(){
    this.log_function("disconnect");

    this.deactivate();
  }

  get is_active(){
    this.log_function("get is_active");

    if (typeof localStorage === "undefined") return false;
    return localStorage.getItem("nest_view_9000_active") || false;
  }

}
