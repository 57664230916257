/**
 * Loads a stylesheet from the specified URL
 * @function load_style
 * @param {string} source - The URL of the script to load.
 */
const load_style = function(source) {
  var mapCSS = document.createElement("link");
  mapCSS.rel = "stylesheet";
  mapCSS.href = source;
  document.body.appendChild(mapCSS);
};


/**
 * Loads a script from the specified URL and calls the optional callback function after the script is loaded.
 * we found a version of this code here https://stackoverflow.com/a/28002292
 * @function load_script
 * @param {string} source - The URL of the script to load.
 * @param {Function} [callback] - An optional callback function to call after the script is loaded.
 */
const load_script = function(source, callback = false) {
  var script = document.createElement("script");
  script.async = true;
  script.defer = "defer";
  script.as = "script";
  script.dataset.turboTrack = "reload";

  script.onload = script.onreadystatechange = (_, isAbort) => {
    if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
      script.onload = script.onreadystatechange = null;
      script = undefined;
      if (!isAbort && callback) callback();
    }
  };

  script.src = source;
  document.body.appendChild(script);
};


/**
 * Gives access to loader functions to load scripts (load_script) and stylesheets (load_style)
 * @mixin useAssetLoader
 * @param {object} controller - The URL of the script to load.
 */
const useAssetLoader = controller => {
  Object.assign(controller, {
    load_style,
    load_script,
  });
};

export {
  load_style,
  load_script,
  useAssetLoader,
};

export default useAssetLoader;
