import { hex as WCAGColorContrastHex} from "wcag-contrast";

/**
 * This module provides utility functions for working with colors.
 * @module color-utils
 */


/**
 * Converts a RGB color string to a hexadecimal color code.
 * @param {string} rgb - The RGBA color string to convert.
 * @returns {string} The hexadecimal color code.
 */
const rgb_to_hex = (rgb) => {
  //* Remove spaces from the RGB color string
  const rgb_clean = rgb.replace(/\s/g, "");

  //* Extract the RGB values
  const matches = rgb_clean.match(/^rgb\((\d+),(\d+),(\d+)\)$/);
  const r = parseInt(matches[1]);
  const g = parseInt(matches[2]);
  const b = parseInt(matches[3]);

  //* Convert each RGB value to hexadecimal
  const rHex = r.toString(16).padStart(2, "0");
  const gHex = g.toString(16).padStart(2, "0");
  const bHex = b.toString(16).padStart(2, "0");

  //* Combine the hexadecimal values
  const hexColor = "#" + rHex + gHex + bHex;

  return hexColor;
};

/**
 * Converts a hexadecimal color code to a RGBA color string.
 * @param {string} hex - The hexadecimal color code to convert.
 * @returns {string} The RGBA color string.
 */
const hex_color_to_rgba = (hex) => {
  const rgba_array = hex_color_to_rgba_array(hex); //* Convert the hexadecimal color code to an RGBA array
  return `rgba(${rgba_array.join(", ")})`; //* Join the elements of the RGBA array into an RGBA color string
};


/**
 * Converts a hexadecimal color code to an RGBA array.
 * @param {string} hex - The hexadecimal color code to convert.
 * @returns {number[]} An array containing the red, green, blue, and alpha values of the converted color code.
 *                     The red, green, and blue values are integers from 0 to 255, and the alpha value is a decimal from 0 to 1.
 *                     Returns an array of [255, 255, 255, 1] if the input hex code is invalid.
 */
const hex_color_to_rgba_array = (hex) => {
  const clean_hex = hex_long(hex);
  const valid_hex_lengths = [4, 5, 7, 9];

  if (valid_hex_lengths.includes(clean_hex.length)) {
    //* The numbers in the array are the positions of each color pair in the string
    //* #FF00BBFF
    //*  1 3 5 7
    const hex_rgba_array = [1, 3, 5, 7].map((position) => clean_hex.slice(position, position + 2));
    const dec_rgba_array = hex_rgba_array.map((rgba_color) => parseInt(rgba_color, 16));
    dec_rgba_array[3] = parseFloat((dec_rgba_array[3] / 255).toFixed(2)); //* Alpha value is from 0-1 not 0-255
    return dec_rgba_array;
  } else {
    return [255, 255, 255, 1];
  }
};


/**
 * Converts a hexadecimal color code to an RGB array.
 * @param {string} hex - The hexadecimal color code to convert.
 * @returns {number[]} An array containing the red, green, and blue values of the converted color code.
 *                     The red, green, and blue values are integers from 0 to 255.
 */
const hex_color_to_rgb_array = (hex) => {
  const rgba_array = hex_color_to_rgba_array(hex);
  rgba_array.pop();

  return rgba_array;
};


/**
 * Converts a hexadecimal color code to an RGBA object.
 * @param {string} hex - The hexadecimal color code to convert.
 * @returns {object} An object containing the red, green, blue, and alpha values of the converted color code.
 *                   The red, green, and blue values are integers from 0 to 255, and the alpha value is a decimal from 0 to 1.
 */
const hex_color_to_rgba_object = (hex) => {
  const rgba_array = hex_color_to_rgba_array(hex);
  const rgba_object = {
    red: rgba_array[0],
    green: rgba_array[1],
    blue: rgba_array[2],
    alpha: rgba_array[3],
  };

  return rgba_object;
};


/**
 * Converts a shorthand hexadecimal color code to a longhand hexadecimal color code.
 * @param {string} hex - The shorthand hexadecimal color code to convert.
 * @returns {string} The longhand hexadecimal color code.
 * @throws {Error} If an invalid HEX code length is provided.
 */
const hex_long = (hex) => {
  const hex_no_hash = hex.replace("#", "");
  let rgb_array = [];
  let red = "";
  let blue = "";
  let green = "";
  let alpha = "";

  switch (hex_no_hash.length) {
    case 3:
      rgb_array = hex_no_hash.split("");
      red = rgb_array[0] + rgb_array[0];
      green = rgb_array[1] + rgb_array[1];
      blue = rgb_array[2] + rgb_array[2];
      alpha = "FF";
      return "#" + red + green + blue + alpha;
    case 4:
      rgb_array = hex_no_hash.split("");
      red = rgb_array[0] + rgb_array[0];
      green = rgb_array[1] + rgb_array[1];
      blue = rgb_array[2] + rgb_array[2];
      alpha = rgb_array[3] + rgb_array[3];
      return "#" + red + green + blue + alpha;
    case 6:
      return hex + "FF";
    case 8:
      return hex;
    default:
      console.error("Invalid HEX code length provided!");
      return hex;
  }
};


/**
 * Removes the alpha channel from a hexadecimal color code if it exists.
 * @param {string} hex - The hexadecimal color code to modify.
 * @returns {string} The modified hexadecimal color code without an alpha channel.
 * @throws {Error} If an invalid HEX code length is provided.
 */
const hex_no_alpha = (hex) => {
  const hex_no_hash = hex.replace("#", "");

  switch (hex_no_hash.length) {
    case 3:
      return hex;
    case 6:
      return hex;
    case 4:
      return hex.slice(0, -1);
    case 8:
      return hex.slice(0, -2);
    default:
      console.error("Invalid HEX code length provided!");
      return hex;
  }
};


/**
 * Calculates the contrasting color to a given hexadecimal color code.
 * @param {string} hex - The hexadecimal color code to find the contrasting color for.
 * @param {object} [user_settings] - Optional user settings to override the default settings.
 * @param {string} [user_settings.dark_output] - The default output color if the input color is light.
 * @param {string} [user_settings.light_output] - The default output color if the input color is dark.
 * @param {number} [user_settings.threshold] - The threshold value at which the input color is considered light or dark. Range is from 0-255.
 * @returns {string} The contrasting color as a hexadecimal color code.
 */
const hex_contrasted_color = (hex, user_settings = {}) => {

  const default_settings = {
    dark_output: "#000000",
    light_output: "#FFFFFF",
    threshold: 186, //* adjust the default threshold value to change when light/dark values switch
  };

  const settings = {
    ...default_settings,
    ...user_settings,
  };

  const rgb_object = hex_color_to_rgba_object(hex);
  const { red, green, blue } = rgb_object;

  //* This algorithum was pulled from:
  //* https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  if (red * 0.299 + green * 0.587 + blue * 0.114 > settings.threshold) {
    return settings.dark_output;
  }

  return settings.light_output;
};


/**
 * Calculates the contrast ratio between two colors using the WCAG algorithm.
 * @param {string} color1 - The first color in hexadecimal format.
 * @param {string} color2 - The second color in hexadecimal format.
 * @returns {number} - The contrast ratio between the two colors.
 * @throws {Error} - If either color is not a valid hexadecimal color.
 * @throws {Error} - If the first color is missing.
 * @throws {Error} - If the second color is missing.
 */
const color_contrast_ratio = (color1 = false, color2 = false) => {
  if (!color1) console.error("Missing first color");
  if (!color2) console.error("Missing second color");
  if (!is_valid_hex_color(color1)) console.error("Invalid hexadecimal color: " + color1);
  if (!is_valid_hex_color(color2)) console.error("Invalid hexadecimal color: " + color2);
  if (!is_valid_hex_color(color1) || !is_valid_hex_color(color2)) return 1;

  return WCAGColorContrastHex(color1, color2);
};

/**
 * Checks if a string is a valid hexadecimal color.
 * @param {string} color - The color string to check.
 * @returns {boolean} - True if the color is valid, false otherwise.
 */
const is_valid_hex_color = (color) => {
  return /^#(?:(?:[\da-f]{3}){1,2}|(?:[\da-f]{4}){1,2})$/i.test(color);
};


/**
 * Determine whether to use the light or dark color in order to create sufficient contrast with the given color
 * @param {string} color - The hex code for the color to test
 * @param {object} settings - (Optional) An object that contains the settings to use for testing the color
 * @param {string} settings.light_test - The hex code for the color to use as a test for light colors
 * @param {string} settings.dark_test - The hex code for the color to use as a test for dark colors
 * @param {string} settings.light_output - The hex code for the color to use if the light test produces the best contrast
 * @param {string} settings.dark_output - The hex code for the color to use if the dark test produces the best contrast
 * @returns {string} - The hex code for the recommended color (either light_output or dark_output)
 */
const dark_or_light_via_color = (color, settings) => {
  const default_settings = {
    light_test: "#FFFFFF",
    dark_test: "#000000",
    light_output: "#FFFFFF",
    dark_output: "#000000",
  };

  const settings_to_use = Object.assign(default_settings, settings);
  const dark = color_contrast_ratio(color, settings_to_use.dark_test);
  const light = color_contrast_ratio(color, settings_to_use.light_test);
  return dark > light ? settings_to_use.dark_output : settings_to_use.light_output;
};


/**
 * Helper function to extend an object with color-related utility functions
 * @param {object} controller - The object to extend with color-related utility functions
 */
const useColorHelper = controller => {
  Object.assign(controller, {
    rgb_to_hex,
    hex_color_to_rgba,
    hex_color_to_rgba_array,
    hex_color_to_rgb_array,
    hex_color_to_rgba_object,
    hex_long,
    hex_no_alpha,
    hex_contrasted_color,
    color_contrast_ratio,
    is_valid_hex_color,
    dark_or_light_via_color,
  });
};

export {
  rgb_to_hex,
  hex_color_to_rgba,
  hex_color_to_rgba_array,
  hex_color_to_rgb_array,
  hex_color_to_rgba_object,
  hex_long,
  hex_no_alpha,
  hex_contrasted_color,
  color_contrast_ratio,
  is_valid_hex_color,
  dark_or_light_via_color,
  useColorHelper,
};

export default useColorHelper;
