/* global Stimulus */
import ApplicationController from "../../view_components/application_controller.js";

//* <button
//*   data-controller="missing-controller-9000"
//*   data-action="missing-controller-9000#check"
//* >
//*   Check
//* </button>

export default class extends ApplicationController {

  connect() {
    this.log_function("connect");
  }

  check() {
    this.log_function("check");
    //* Get all HTML elements with data-controller attribute
    const elements = Array.from(document.querySelectorAll("[data-controller]"));

    //* Array to store missing controllers
    const missingControllers = new Set();

    //* Variable to store if there is an element with a data-controller attribute with no value
    let blank_controllers = 0;

    //* Check if each controller is registered
    elements.forEach((element) => {
      const requestedControllers = element.getAttribute("data-controller").trim().replace(/\n/g, " ").replace(/\s+/g, " ").split(" ");

      requestedControllers.forEach((controllerName) => {
        if (controllerName) {
          if (!Stimulus.controllers.find((controller) => controller.identifier === controllerName)) {
            missingControllers.add(controllerName);
          }
        } else {
          blank_controllers ++;
        }
      });
    });

    //* Alert or print results
    const blank_controller_warning = blank_controllers > 0
      ? `${blank_controllers} element(s) detected with a blank data-controller attribute\n`
      : "";
    const missing_controller_warning = missingControllers.size > 0
      ? `Missing controllers: \n*${Array.from(missingControllers).join("\n* ")}\n`
      : "All controllers are accounted for.\n";

    alert(`${blank_controller_warning}${missing_controller_warning}`);
  }

  disconnect() {
    this.log_function("disconnect");
  }

}
