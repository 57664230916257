/* global Stimulus */

//* toolkit 9000
import color_picker_9000 from "./color_picker_9000/index.js";
Stimulus.register("color-picker-9000", color_picker_9000);

import font_picker_9000 from "./font_picker_9000/index.jsx";
Stimulus.register("font-picker-9000", font_picker_9000);

import nest_view_9000 from "./nest_view_9000/index.js";
Stimulus.register("nest-view-9000", nest_view_9000);

import text_editor_9000 from "./text_editor_9000/index.js";
Stimulus.register("text-editor-9000", text_editor_9000);

import missing_controller_9000 from "./missing_controller_9000/index.js";
Stimulus.register("missing-controller-9000", missing_controller_9000);
