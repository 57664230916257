/* global Stimulus */
import { Controller } from "@hotwired/stimulus";
import get from "lodash/get";

if (window.Stimulus){
  //? How do you activate global debugging in Stimulus?
  Stimulus.debug = false;
}

export default class ApplicationController extends Controller {

  debug = false;

  log_function(function_name, more_info_1 = "", more_info_2 = ""){
    const lightColorMap = {
      connect:    "green",
      disconnect: "red",
      helper:     "darkCyan",
      open:       "blue",
      close:      "orange",
      reset:      "darkred",
      default:    "darkCyan",
    };

    const darkColorMap = {
      connect:    "limegreen",
      disconnect: "red",
      helper:     "lightblue",
      open:       "aqua",
      close:      "orange",
      reset:      "darkred",
      default:    "lightblue",
    };

    const darkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    const colorMap = darkMode ? darkColorMap : lightColorMap;
    const color = get(colorMap, function_name, colorMap.default);
    let identifier = "identifier";

    try {
      identifier = this.identifier;
    } catch (error) {
      console.error(error);
    }

    if(this.debug){
      console.log(`%c ${identifier}#${function_name}()`, `color: ${color};`, more_info_1, more_info_2);
    }


  }


  log_error_function(function_name, more_info_1 = "", more_info_2 = "") {
    let identifier = "identifier";

    try {
      identifier = this.identifier;
    } catch (error) {
      console.error(error);
    }

    console.error(`${identifier}#${function_name}()`, more_info_1, more_info_2);
  }


}
