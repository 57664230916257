import ApplicationController from "../../view_components/application_controller.js";

//* <button
//*   data-controller="text-editor-9000"
//*   data-action="text-editor-9000#toggle"
//* >
//*   Toggle
//* </button>

export default class extends ApplicationController  {

  connect() {
    this.log_function("connect");

    this.is_active ? this.activate() : this.deactivate();
  }


  toggle(){
    this.log_function("toggle");

    this.is_active ? this.deactivate() : this.activate();
  }

  activate(){
    this.log_function("activate");

    document.designMode = "on";
    this.element.classList.add("active");
    localStorage.setItem("time_saver_9000_design_mode", true);
  }

  deactivate(){
    this.log_function("deactivate");

    document.designMode = "off";
    this.element.classList.remove("active");
    localStorage.removeItem("time_saver_9000_design_mode");
  }

  disconnect(){
    this.log_function("disconnect");

    this.deactivate();
  }

  get is_active() {
    this.log_function("get is_active");

    if (typeof localStorage === "undefined") return false;
    return localStorage.getItem("time_saver_9000_design_mode") || false;
  }

}
